.bottom-curtain {
  height: 100vh;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 200;
  background: #ff4c33;
  transform: translate3d(0, 100%, 0);
  opacity: 1;
  transition: 0ms ease all 500ms, 0ms opacity 500ms;
  -will-change: transform; }
  .bottom-curtain.leaving {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    height: 0;
    transition: 500ms cubic-bezier(0.48, 0.04, 0.52, 0.96) transform 100ms, 1ms linear opacity, 200ms linear height 500ms; }
