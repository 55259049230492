.background-music {
  background: #ff4c33;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0; }
  .background-music:global {
    animation: 1s fadeIn ease forwards; }

:global(html.leaving) .background:global {
  animation: 0.5s fadeUpOut ease forwards; }

:global(html.leaving) .section-title {
  opacity: 1; }
  :global(html.leaving) .section-title:global {
    animation: 0.3s fadeUpOut forwards; }

:global(html.leaving) .albums-wrap .album-wrap .album-inner {
  opacity: 1; }
  :global(html.leaving) .albums-wrap .album-wrap .album-inner:global {
    animation: 0.3s fadeUpOut forwards; }

.wrap {
  padding: 1rem 0; }

.content-section {
  position: relative;
  z-index: 3;
  width: 100%;
  max-width: 72rem;
  margin: 0 auto;
  padding-top: 10rem; }

.section-title {
  padding: 1rem 2.8rem;
  text-transform: uppercase; }
  .section-title:global {
    animation: 0.96s fadeIn ease forwards; }

.albums-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: row;
  margin: 0 0.5rem;
  padding-bottom: 5rem; }
  @media (min-width: 820px) {
    .albums-wrap {
      grid-auto-flow: column;
      grid-template-columns: 1fr 1fr 1fr;
      margin: 0 1.8rem; } }
  .albums-wrap:global {
    animation: 1.1s fadeIn ease forwards; }
  .albums-wrap .album-wrap {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    color: #ffffff; }
    .albums-wrap .album-wrap .album-inner {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      right: 0.5rem;
      bottom: 0.5rem;
      overflow: hidden;
      opacity: 0;
      cursor: pointer;
      transition: 200ms linear border; }
      .albums-wrap .album-wrap .album-inner:global {
        animation: 0.9s fadeInFar forwards; }
      @media (min-width: 820px) {
        .albums-wrap .album-wrap .album-inner {
          position: absolute;
          top: 1rem;
          left: 1rem;
          right: 1rem;
          bottom: 1rem; } }
      .albums-wrap .album-wrap .album-inner:hover .album-fg {
        opacity: 0.5;
        transition: 400ms linear opacity; }
      .albums-wrap .album-wrap .album-inner:hover .album-title {
        opacity: 1;
        transform: translate(0, -50%); }
      .albums-wrap .album-wrap .album-inner .album-bg {
        background-size: cover;
        background-position: center;
        height: 100%;
        width: 100%; }
      .albums-wrap .album-wrap .album-inner .album-fg {
        height: 100%;
        width: 100%;
        background: #ff4c33;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: 500ms linear opacity; }
      .albums-wrap .album-wrap .album-inner .album-title {
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 0.5rem;
        padding: 1rem;
        text-transform: uppercase;
        transform: translate(0, -40%);
        transition: 450ms cubic-bezier(0.4, 0.8, 0.74, 1) all;
        width: 1px;
        font-size: 0.75rem; }
        @media (min-width: 820px) {
          .albums-wrap .album-wrap .album-inner .album-title {
            font-size: 1rem; } }
