.wrap {
  position: fixed;
  z-index: 99990;
  bottom: 0;
  left: 1rem;
  right: 1rem;
  height: 12px;
  background: transparent; }
  @media (min-width: 820px) {
    .wrap {
      left: 2.8rem;
      right: 2.8rem; } }

.inner {
  width: 0%;
  position: absolute;
  left: 0;
  height: 2px;
  top: 0;
  background: #ffffff;
  will-change: width; }
