:global(html.leaving) .product:global {
  animation: 300ms fadeUpOut forwards; }

.products-wrap {
  opacity: 1; }

.product {
  padding: 1rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem; }
  @media (min-width: 820px) {
    .product {
      flex-direction: row;
      align-items: center;
      justify-content: center; } }
  .product:global {
    animation: 1000ms fadeIn forwards; }
  .product .product-image-wrap {
    width: 100%;
    flex: 1 1 50%; }
    @media (min-width: 820px) {
      .product .product-image-wrap {
        width: 76%;
        flex: 1 1 76%; } }
    .product .product-image-wrap .product-image {
      height: 0;
      padding-bottom: 100%;
      width: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat; }
      @media (min-width: 820px) {
        .product .product-image-wrap .product-image {
          padding-bottom: 76%; } }
      .product .product-image-wrap .product-image:global {
        animation: 900ms fadeInFar forwards; }
  .product .product-details {
    flex: 1 0 100%;
    width: 100%;
    opacity: 1; }
    .product .product-details:global {
      animation: 500ms fadeInFar forwards; }
    @media (min-width: 820px) {
      .product .product-details {
        width: 40%;
        flex: 1 1 40%; } }
  .product .product-title {
    opacity: 0;
    padding-bottom: 1rem; }
    .product .product-title:global {
      animation: 500ms fadeInFar forwards; }
  .product .product-price {
    opacity: 0;
    padding-bottom: 1rem; }
    .product .product-price:global {
      animation: 500ms fadeInFar forwards; }
  .product .product-sizes {
    opacity: 0;
    padding-bottom: 3rem; }
    .product .product-sizes:global {
      animation: 500ms fadeInFar forwards; }
    .product .product-sizes .size-button {
      opacity: 1;
      padding: 0.5rem;
      color: #ffffff;
      border: 1px solid #ffffff;
      margin-right: 0.5rem;
      transition: 150ms linear all; }
      .product .product-sizes .size-button.selected {
        background: #ffffff;
        color: #ff4c33; }
      .product .product-sizes .size-button:hover {
        background: #ffffff;
        color: #ff4c33; }
  .product .product-button-wrap {
    opacity: 0; }
    .product .product-button-wrap:global {
      animation: 400ms fadeInFar forwards; }
    .product .product-button-wrap .product-warning {
      padding-top: 1rem;
      text-transform: uppercase; }
    .product .product-button-wrap .product-button {
      background: #ffffff;
      padding: 0.5rem 1rem;
      padding-top: 0.6rem;
      text-transform: uppercase;
      color: #ff4c33;
      font-weight: bold;
      opacity: 0.7;
      transition: 150ms linear all;
      border: 1px solid #ffffff;
      position: relative;
      width: 100%; }
      @media (min-width: 820px) {
        .product .product-button-wrap .product-button {
          width: auto; } }
      .product .product-button-wrap .product-button:hover {
        background: #ff4c33;
        color: #ffffff; }
      .product .product-button-wrap .product-button.valid {
        opacity: 1; }
      .product .product-button-wrap .product-button.loading {
        padding-right: 3rem;
        color: #ff4c33 !important;
        background: #ffffff !important; }
      .product .product-button-wrap .product-button :global(.sk-spinner) {
        display: inline-block;
        margin-left: 1rem;
        height: 1rem;
        width: 1rem;
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translate(0, -50%);
        color: #ff4c33;
        animation: none; }
