.background-shop {
  background: #ff4c33;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0; }
  .background-shop:global {
    animation: 1s fadeIn ease forwards; }

:global(html.leaving) .background:global {
  animation: 0.5s fadeUpOut ease forwards; }

.wrap {
  padding: 1rem; }

.content-section {
  padding-top: 5rem;
  padding-bottom: 8rem;
  position: relative;
  z-index: 3;
  width: 100%;
  margin: 0 auto; }
