@font-face {
  src: url("../assets/fonts/pano_light.woff2") format("woff2"), url("../assets/fonts/pano_light.woff") format("woff");
  font-family: 'text';
  font-weight: 100;
  font-style: normal; }

@font-face {
  src: url("../assets/fonts/pano_regular.woff2") format("woff2"), url("../assets/fonts/pano_regular.woff") format("woff");
  font-family: 'text';
  font-weight: normal;
  font-style: normal; }

@font-face {
  src: url("../assets/fonts/pano_bold.woff2") format("woff2"), url("../assets/fonts/pano_bold.woff") format("woff");
  font-family: 'text';
  font-weight: bold;
  font-style: normal; }

html {
  font-family: "text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: normal; }

.title-xxl {
  font-size: 14vw;
  font-weight: bold;
  line-height: 1; }
  @media (min-width: 1434px) {
    .title-xxl {
      font-size: 10rem; } }

.title-xl {
  font-size: 5rem;
  font-weight: bold;
  line-height: 1; }

.title-l {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1; }

.title-m {
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1; }

.button-l {
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.02rem; }

.text {
  font-size: 1.2rem;
  font-weight: 500; }

.text-s {
  font-size: 18px;
  font-weight: 500; }
  @media (min-width: 820px) {
    .text-s {
      font-size: 0.925rem; } }

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-kerning: normal;
  background: #ffffff;
  color: #121212; }

html,
body {
  margin: 0;
  padding: 0; }
  html *,
  body * {
    box-sizing: border-box; }

hr {
  margin: 0;
  padding: 0;
  border: none;
  border-bottom: 2px solid #ff1a1a; }

button,
input,
textarea,
select {
  outline: none;
  border: none;
  background: transparent;
  padding: 0px;
  margin: 0px;
  -webkit-appearance: none;
  font-family: 'text'; }

button {
  white-space: nowrap;
  cursor: pointer; }

canvas {
  -webkit-touch-callout: none;
  -webkit-user-select: none; }

.axis {
  position: fixed;
  z-index: 99999;
  display: none; }
  .axis .vaxis {
    position: fixed;
    width: 1px;
    background: rgba(250, 250, 250, 0.1);
    z-index: -1;
    transform: translate(-1px, 0);
    top: 0;
    left: 50%;
    height: 100vh; }
  .axis .haxis {
    position: fixed;
    height: 1px;
    background: rgba(250, 250, 250, 0.1);
    z-index: -1;
    transform: translate(0, -1px);
    top: 50%;
    left: 0;
    width: 100vw; }
  .axis .lmargin {
    position: fixed;
    width: 1px;
    background: rgba(250, 250, 250, 0.1);
    z-index: -1;
    top: 0;
    left: 1rem;
    height: 100vh; }
    @media (min-width: 820px) {
      .axis .lmargin {
        left: 2.8rem; } }
  .axis .rmargin {
    position: fixed;
    width: 1px;
    background: rgba(250, 250, 250, 0.1);
    z-index: -1;
    top: 0;
    right: 1rem;
    height: 100vh; }
    @media (min-width: 820px) {
      .axis .rmargin {
        right: 2.8rem; } }
  .axis .tmargin {
    position: fixed;
    height: 1px;
    background: rgba(250, 250, 250, 0.1);
    z-index: -1;
    left: 0;
    top: 1rem;
    width: 100vw; }
    @media (min-width: 820px) {
      .axis .tmargin {
        top: 2rem; } }
  .axis .bmargin {
    position: fixed;
    height: 1px;
    background: rgba(250, 250, 250, 0.1);
    z-index: -1;
    left: 0;
    bottom: 1rem;
    width: 100vw; }
    @media (min-width: 820px) {
      .axis .bmargin {
        bottom: 2rem; } }

.borders_ {
  display: none; }
  .borders_ > div {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 2px;
    background: #ff4c33;
    z-index: 8888888;
    display: none; }
    @media (min-width: 820px) {
      .borders_ > div {
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: block; } }
  .borders_ .border_t {
    bottom: auto;
    width: auto;
    position: fixed; }
  .borders_ .border_b {
    top: auto;
    width: auto; }
  .borders_ .border_l {
    right: auto;
    height: auto; }
  .borders_ .border_r {
    left: auto;
    height: auto; }

.bg-white {
  background: #ffffff; }

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(0, 16px); }
  to {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes fadeInFar {
  from {
    opacity: 0;
    transform: translate(0, 2rem); }
  to {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes fadeDownIn {
  from {
    opacity: 0;
    transform: translate(0, -6px); }
  to {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes fadeDownOut {
  from {
    opacity: 1;
    transform: translate(0, 0); }
  to {
    opacity: 0;
    transform: translate(0, 12px); } }

@keyframes fadeDownOutFar {
  from {
    opacity: 1;
    transform: translate(0, 0); }
  to {
    opacity: 0;
    transform: translate(0, 2rem); } }

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0, 0); }
  to {
    opacity: 0;
    transform: translate(0, 2rem); } }

@keyframes fadeUpOut {
  from {
    opacity: 1;
    transform: translate(0, 0); }
  to {
    opacity: 0;
    transform: translate(0, -0.5rem) scale(1.001); } }

@keyframes slideUp {
  from {
    transform: translate(0, 100%); }
  to {
    transform: none; } }

html {
  background: #121212; }

body {
  background: #121212;
  min-height: 100vh;
  color: #ffffff; }
  body a {
    color: #ff4c33;
    text-decoration: none; }
    body a:hover {
      color: #ef0f0f; }
  body p a {
    text-decoration: underline; }

.col-1 {
  width: 3rem; }

.col-2 {
  width: 7rem; }

.col-3 {
  width: 11rem; }

.col-4 {
  width: 15rem; }

.col-5 {
  width: 19rem; }

.col-6 {
  width: 23rem; }

.col-7 {
  width: 27rem; }

.col-8 {
  width: 31rem; }

.col-9 {
  width: 35rem; }

.col-10 {
  width: 39rem; }

.col-11 {
  width: 43rem; }

.col-12 {
  width: 47rem; }

.vscroll {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

::selection {
  color: #3f00ff;
  background: #ef0f0f; }

.mobile-only {
  display: block; }
  @media (min-width: 820px) {
    .mobile-only {
      display: none; } }

.desktop-only {
  display: none; }
  @media (min-width: 820px) {
    .desktop-only {
      display: block; } }

#svglogo,
#circle-dot,
#circle-dot-line {
  fill: #ff4c33; }

#wave {
  fill: #ffffff; }

html {
  font-size: 4vw; }
  @media screen and (min-width: 400px) {
    html {
      font-size: 14px; } }
  @media (min-width: 820px) {
    html {
      font-size: 14px; } }
  @media (min-width: 1127px) {
    html {
      font-size: 15px; } }
  @media (min-width: 1434px) {
    html {
      font-size: 1.1vw; } }
  @media screen and (min-width: 1820px) {
    html {
      font-size: 22px; } }

.red {
  color: #ff4c33; }

.white {
  color: #ffffff; }

.black {
  color: #121212; }

.grey {
  color: #222;
  font-weight: 500;
  opacity: 0.5; }

.underline {
  text-decoration: underline; }

.italic {
  font-style: italic; }

.bold {
  font-weight: 600; }

.heavy {
  font-weight: bold; }

.uppercase {
  text-transform: uppercase; }

.right {
  text-align: right;
  float: right; }

.center {
  text-align: center;
  margin: 0 auto; }

.center-desktop {
  text-align: left; }
  @media (min-width: 820px) {
    .center-desktop {
      text-align: center; } }

.center-col-max {
  text-align: left; }
  @media screen and (min-width: 39rem) {
    .center-col-max {
      text-align: center; } }

.dot-bg {
  background-size: 4px;
  -webkit-transform: translateZ(0); }

.flex-apart {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.middle {
  align-items: center;
  vertical-align: middle; }
  .middle :global(svg) {
    vertical-align: middle; }
