.wrap {
  position: fixed;
  z-index: 999900;
  background: transparent;
  top: 0;
  bottom: 0; }
  .wrap .nav-link {
    padding: 1rem 1rem;
    color: #ffffff;
    font-weight: bold; }
    @media (min-width: 820px) {
      .wrap .nav-link {
        padding: 1rem 1.8rem; } }
    .wrap .nav-link a,
    .wrap .nav-link button {
      font-weight: bold;
      color: #ffffff;
      font-size: 1rem; }
  .wrap .tl {
    position: fixed;
    top: 0rem;
    left: 0rem; }
    @media (min-width: 820px) {
      .wrap .tl {
        top: 1rem;
        left: 1rem; } }
  .wrap .tr {
    position: fixed;
    top: 0rem;
    right: 0rem; }
    @media (min-width: 820px) {
      .wrap .tr {
        top: 1rem;
        right: 1rem; } }
  .wrap .bl {
    position: fixed;
    bottom: 0rem;
    left: 0rem; }
    @media (min-width: 820px) {
      .wrap .bl {
        bottom: 1rem;
        left: 1rem; } }
  .wrap .br {
    position: fixed;
    bottom: 0rem;
    right: 0rem; }
    @media (min-width: 820px) {
      .wrap .br {
        bottom: 1rem;
        right: 1rem; } }

.link {
  opacity: 0.7;
  transition: 0.2s linear opacity, 0.3s cubic-bezier(0.76, -0.14, 0.24, 1.14) background;
  background-position: bottom left;
  background-image: linear-gradient(to right, #ffffff, #ffffff);
  background-size: 0% 1px;
  background-repeat: no-repeat;
  padding-bottom: 0.1rem;
  cursor: pointer; }
  .link:hover {
    opacity: 0.9; }

.is-current-route .link {
  opacity: 0.3;
  transition: 1s linear opacity, 0.3s cubic-bezier(0.76, -0.14, 0.24, 1.14) background; }
