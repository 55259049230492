.background {
  background: url("../../assets/img/videos.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0; }
  .background:global {
    animation: 1s fadeIn ease forwards; }

:global(html.leaving) .background:global {
  animation: 0.5s fadeUpOut ease forwards; }

:global(html.leaving) .video-wrap:global {
  animation: 0.4s fadeDownOut cubic-bezier(0.32, 0, 0.66, -0.02) forwards; }

.wrap {
  padding: 1rem; }

.content {
  position: relative;
  padding-top: 10rem;
  z-index: 3; }

.video-wrap {
  max-width: 45rem;
  margin: 0 auto;
  padding-bottom: 10rem; }
  .video-wrap:global {
    animation: 0.5s fadeInFar ease forwards; }
  .video-wrap .iframe-wrap {
    margin: 1rem 0;
    width: 100%;
    background-size: cover;
    background-position: center;
    padding: 0; }
    .video-wrap .iframe-wrap :global(iframe) {
      width: 100%;
      padding: 0;
      margin: 0;
      overflow: hidden;
      height: 56.25vw; }
      @media (min-width: 820px) {
        .video-wrap .iframe-wrap :global(iframe) {
          height: 25rem; } }
  .video-wrap .details {
    opacity: 0.7;
    text-transform: uppercase; }
  .video-wrap .subtext {
    opacity: 0.5;
    font-weight: lighter; }
