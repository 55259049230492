.wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #ff6c58;
  padding: 1rem;
  z-index: 999909;
  transform: translate(0, -100%);
  opacity: 0;
  transition: 400ms cubic-bezier(0.62, -0.04, 0.38, 1.04) transform, 250ms linear opacity 250ms; }
  .wrap.open {
    transition: 400ms cubic-bezier(0.62, -0.04, 0.38, 1.04) transform, 250ms linear opacity;
    transform: translate(0, 0);
    opacity: 1; }
  .wrap .close {
    position: absolute;
    left: 1rem;
    top: 1rem;
    bottom: 1rem; }
  .wrap .center {
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    max-width: 22rem;
    margin: 0 auto; }
  .wrap .title {
    padding: 1rem; }

.right {
  position: absolute;
  right: 1rem;
  top: 1rem; }
  .right .item-name {
    max-width: 12rem;
    text-align: right; }
  .right .item-image {
    height: 4rem;
    width: 4rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; }

.product-button {
  background: transparent;
  padding: 0.5rem 1rem;
  padding-top: 0.6rem;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  opacity: 1;
  transition: 150ms linear all;
  border: 1px solid #ffffff;
  position: relative;
  width: 100%; }
  .product-button:hover {
    background: #ff4c33;
    color: #ffffff; }
