.loader {
  width: 250px;
  height: 250px; }
  .loader svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    transform-origin: 50% 50%;
    width: 500px;
    height: 500px;
    backface-visibility: hidden; }
