.logo-wrap {
  position: fixed;
  z-index: 800;
  transition: 2s cubic-bezier(0.2, 1, 0.2, 1) all; }
  .logo-wrap .cart {
    opacity: 0;
    left: 50%;
    display: none;
    position: absolute;
    transition: 800ms ease all;
    transform: translate(-50%, -4rem);
    position: absolute; }
  .logo-wrap .logo-name-wrap {
    transition: 500ms cubic-bezier(0.16, 1.08, 0.38, 0.98) all;
    position: relative;
    margin: 0 auto; }
  .logo-wrap.centered {
    left: 0;
    right: 0;
    top: 50%; }
    @media (min-width: 820px) {
      .logo-wrap.centered {
        top: 50%; } }
  .logo-wrap.bottom {
    top: 0.4rem;
    left: 0;
    right: 0;
    transition: 500ms cubic-bezier(0.16, 1.08, 0.38, 0.98) all; }
    @media (min-width: 820px) {
      .logo-wrap.bottom {
        top: 2.8rem; } }
  .logo-wrap.store {
    top: 0.4rem;
    left: 0;
    right: 0; }
    @media (min-width: 820px) {
      .logo-wrap.store {
        top: 1.4rem;
        bottom: 1.8rem; } }
    .logo-wrap.store .logo-name-wrap {
      transform: translate(-50%, 100%);
      opacity: 0; }
    .logo-wrap.store .cart {
      top: 0.5rem;
      opacity: 0.7;
      left: 50%;
      transition: 800ms ease all, 200ms linear opacity;
      transform: translate(-50%, 0); }
      @media (min-width: 820px) {
        .logo-wrap.store .cart {
          top: auto;
          bottom: 0; } }
      .logo-wrap.store .cart:hover {
        opacity: 1; }
  .logo-wrap button {
    font-size: 1rem;
    color: #ffffff;
    display: block;
    text-align: center;
    opacity: 0.7;
    cursor: pointer;
    transition: 100ms ease all; }
    .logo-wrap button:hover {
      opacity: 0.9; }
  .logo-wrap .logo-inner {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    font-size: 1.8rem; }
    .logo-wrap .logo-inner .logo-name {
      flex: 0 0 auto;
      font-weight: bold;
      padding-right: 0.5rem;
      display: none; }
      @media (min-width: 820px) {
        .logo-wrap .logo-inner .logo-name {
          display: block; } }
    .logo-wrap .logo-inner .logo-line {
      width: 100%;
      flex: 1 0 100%;
      border-bottom: 3px solid #ffffff;
      display: none; }
      @media (min-width: 820px) {
        .logo-wrap .logo-inner .logo-line {
          display: block; } }
      .logo-wrap .logo-inner .logo-line ::after {
        content: ' '; }
    .logo-wrap .logo-inner .logo-symbol {
      flex: 0 0 0%; }
      .logo-wrap .logo-inner .logo-symbol svg {
        fill: #ffffff; }

.svg-icon {
  height: 1.2rem;
  width: 5.5rem; }
  @media (min-width: 820px) {
    .svg-icon {
      height: 1.7rem;
      width: 5.5rem;
      transform: translate(0, 0.1rem); } }

.cart-svg-icon {
  opacity: 1;
  height: 1.5rem;
  width: 5.5rem;
  stroke: #ffffff;
  fill: #ffffff;
  color: #ffffff; }
