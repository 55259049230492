.background {
  background: url("../../assets/img/home.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0; }
  .background:global {
    animation: 1s fadeIn ease forwards; }

:global(html.leaving) .background:global {
  animation: 0.5s fadeUpOut ease forwards; }

.wrap {
  padding: 1rem; }

.social {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 1rem;
  color: #ffffff;
  display: flex;
  z-index: 1000000; }
  @media (min-width: 820px) {
    .social {
      top: 50%;
      left: auto;
      bottom: auto;
      right: 2.8rem;
      transform: translate(0, -50%);
      display: flex;
      flex-direction: column; } }
  .social .social-link {
    flex: 1 1 0;
    padding: 0rem 0.6rem;
    color: #ffffff;
    font-weight: bold;
    opacity: 0.7; }
    .social .social-link svg {
      color: #ffffff;
      fill: #ffffff;
      height: 1.2rem;
      width: 1.2rem; }
      @media (min-width: 820px) {
        .social .social-link svg {
          height: 1.8rem;
          width: 1.8rem; } }
    @media (min-width: 820px) {
      .social .social-link {
        padding: 1rem 1rem; } }
    .social .social-link:hover {
      opacity: 1; }
