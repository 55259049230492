.background {
  background: #121212;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0; }
  .background:global {
    animation: 1s fadeIn ease forwards; }

:global(html.leaving) .background:global {
  animation: 0.5s fadeUpOut ease forwards; }

.wrap {
  padding: 1rem;
  padding-bottom: 4rem; }

.items-wrap {
  color: #ffffff;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 72rem;
  margin: 0 auto;
  padding-top: 20vh;
  opacity: 0.7;
  padding-bottom: 4rem; }
  @media (min-width: 820px) {
    .items-wrap {
      padding-top: 30vh;
      padding-right: 2rem;
      padding-left: 2rem; } }
  .items-wrap:global {
    animation: 1s fadeIn ease forwards; }

.cart-title {
  text-transform: uppercase;
  padding-bottom: 1rem; }

.item-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-top: 1px solid #ffffff;
  padding: 2rem 0; }
  .item-wrap .item-image-wrap {
    height: 16rem;
    width: 16rem;
    flex: 0 0 16rem;
    background: #ff4c33;
    border-radius: 1000px;
    padding: 0;
    line-height: 0;
    position: relative; }
    @media (min-width: 820px) {
      .item-wrap .item-image-wrap {
        height: 10rem;
        width: 10rem;
        flex: 0 0 10rem; } }
  .item-wrap .item-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 16rem;
    width: 16rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }
    @media (min-width: 820px) {
      .item-wrap .item-image {
        height: 8rem;
        width: 8rem; } }
  .item-wrap .item-title {
    text-transform: uppercase;
    padding: 0 2rem;
    flex: 1 1 100%; }
  .item-wrap .item-quantity {
    flex: 0 0 auto; }
  .item-wrap .item-price {
    flex: 0 0 auto;
    padding: 0 1rem; }
  .item-wrap .item-remove {
    flex: 0 0 auto; }

.cart-total {
  width: 100%;
  text-align: right;
  padding: 3rem 0;
  border-top: 1px solid #ffffff;
  padding-right: 6rem; }

.cta {
  padding: 0.8rem;
  color: #ffffff; }
