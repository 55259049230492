.wrap {
  position: relative; }

.outer {
  display: flex;
  height: 3rem;
  width: 3rem;
  position: relative;
  cursor: pointer; }
  @media (min-width: 820px) {
    .outer {
      height: 5rem;
      width: 5rem; } }
  .outer .inner-1 {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    border-bottom: 1px solid #ffffff;
    transform: rotate(-45deg);
    transform-origin: center; }
  .outer .inner-2 {
    width: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    border-bottom: 1px solid #ffffff;
    transform: rotate(45deg);
    transform-origin: center; }
