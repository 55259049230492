.wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%; }
  .wrap.reverse {
    flex-direction: row-reverse;
    text-align: right; }
    .wrap.reverse .text {
      padding-right: 0;
      padding-left: 0.5rem; }
    .wrap.reverse .line {
      background-position: left center; }
  .wrap.in-center .text {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }

.text {
  display: inline-block;
  flex: 0 0 auto;
  padding-right: 0.5rem; }

.line {
  display: inline-block;
  background-image: linear-gradient(to right, #ffffff, #ffffff);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 100% 2px;
  flex: 1 1 100%; }
