.background {
  background: url("../../assets/img/detroit.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0; }
  .background:global {
    animation: 1s fadeIn ease forwards; }

:global(html.leaving) .background:global {
  animation: 0.5s fadeUpOut ease forwards; }

.wrap {
  padding: 1rem;
  padding-bottom: 5rem; }

.shows-wrap {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 72rem;
  margin: 0 auto;
  padding-top: 20vh;
  opacity: 0.7;
  padding-bottom: 4rem; }
  @media (min-width: 820px) {
    .shows-wrap {
      padding-top: 30vh;
      padding-right: 2rem;
      padding-left: 2rem; } }
  .shows-wrap:global {
    animation: 1s fadeIn ease forwards; }

.concert-country {
  padding-bottom: 2rem;
  padding-top: 2rem; }

.show-button {
  width: 100%;
  display: block;
  opacity: 0;
  font-weight: 100; }
  .show-button:global {
    animation: 0.7s fadeInFar ease forwards; }
  .show-button :global(.__text-with-line) {
    background-image: linear-gradient(to right, transparent, transparent); }
  .show-button:hover :global(.__text-with-line) {
    background-image: linear-gradient(to right, #ffffff, #ffffff); }

:global(html.leaving) .concert-country {
  opacity: 1; }
  :global(html.leaving) .concert-country:global {
    animation: 0.4s fadeUpOut ease forwards; }

:global(html.leaving) .show-button {
  opacity: 1; }
  :global(html.leaving) .show-button:global {
    animation: 0.3s fadeUpOut ease forwards; }

.show {
  display: flex;
  flex-direction: row;
  opacity: 1;
  text-transform: uppercase;
  padding: 0.1rem 0;
  opacity: 0.5;
  cursor: pointer;
  transition: 0.1s linear opacity;
  font-weight: 100;
  font-size: 1rem;
  color: #ffffff;
  width: 100%; }
  .show:hover {
    opacity: 1;
    font-weight: lighter; }
  .show .show-date {
    flex: 0 0 40%; }
  .show .show-city {
    flex: 0 0 60%;
    text-align: right;
    text-overflow: nowrap; }
  .show .show-name {
    display: none; }
  @media (min-width: 820px) {
    .show .show-date {
      flex: 0 0 20%; }
    .show .show-city {
      flex: 0 0 30%;
      text-align: left; }
    .show .show-name {
      display: block;
      flex: 1 0 50%;
      text-align: right;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; } }
